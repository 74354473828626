<template>
  <div>
    <GmapMap
      ref="mymap"
      :center="startLocation"
      :zoom="13"
      style="width: 100%; height: 80vh"
    >
      <gmap-marker
        v-for="(item, key) in coordinates"
        :key="key"
        :position="getPosition(item)"
        :clickable="true"
        @click="toggleInfo(item, key)"
      />
    </GmapMap>
  </div>
</template>
<script>
export default {
  data() {
    return {
      startLocation: {
        lat: -1.949658006530648,
        lng: 30.061004297585573,
      },
      coordinates: {
        0: {
          full_name: "Evolve",
          lat: "-1.967752644704206",
          lng: "30.05773378147658",
        },
      },
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  created() {
    this.$getLocation({})
      .then((coordinates) => {
        this.coordinates = coordinates;
      })
      .catch((error) => {});
  },
  methods: {
    getPosition: function (marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
    },
    toggleInfo: function (marker, key) {
      this.infoPosition = this.getPosition(marker);
      this.infoContent = marker.full_name;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },
  },
};
</script>
