<template>
  <div class="Contact">
    <div class="contact_container">
      <div class="contact_container_form">
        <h1>Contact us</h1>
        <form class="">
          <div class="input_container">
            <input type="text" placeholder="Email" />
          </div>
          <div class="input_container">
            <textarea placeholder="Message"> </textarea>
          </div>
          <button class="submit_btn">Submit</button>
        </form>
      </div>
      <div class="contact_img">
        <!-- <img src="@/assets/home/contact-phone.jpg" alt="contact image"/> -->
        <div class="text_contacts">
          <p>
            <a href="tel:+250 788 397938" class="icon">
              <img src="@/assets/svg/icons/phone.svg" alt="Phone" />
            </a>
            <a href="tel:+250 788 397938">+250 788 397938</a>
          </p>
          <p>
            <a href="mailto:info@evolve.rw" class="icon">
              <img alt="email" src="@/assets/svg/icons/email.svg" />
            </a>
            <a href="mailto:info@evolve.rw">info@evolve.rw</a>
          </p>
          <p>
            <a
              href="https://www.facebook.com/eVolveRwanda/"
              target="__blank"
              class="icon"
            >
              <img src="@/assets/svg/icons/facebook.svg" alt="Facebook" />
            </a>
            <a target="__blank" href="https://www.facebook.com/eVolveRwanda/"
              >@eVolveRwanda
            </a>
          </p>
          <p>
            <a
              href="https://www.instagram.com/evolveltd/?hl=en"
              target="__blank"
              class="icon"
            >
              <img src="@/assets/svg/icons/instagram.svg" alt="Instagram" />
            </a>
            <a
              target="__blank"
              href="https://www.instagram.com/evolveltd/?hl=en"
              >@evolveltd</a
            >
          </p>
          <p>
            <a
              target="__blank"
              href="https://twitter.com/evolve_limited"
              class="icon"
            >
              <img src="@/assets/svg/icons/twitter.svg" alt="Twitter" />
            </a>
            <a target="__blank" href="https://twitter.com/evolve_limited"
              >@evolve-limited</a
            >
          </p>
          <p>
            <a
              target="__blank"
              href="https://www.linkedin.com/company/evolve-limited-rwanda/"
              class="icon"
            >
              <img src="@/assets/svg/icons/linkedin.svg" alt="Linkedin" />
            </a>
            <a
              target="__blank"
              href="https://www.linkedin.com/company/evolve-limited-rwanda/"
              >@evolvo-limited</a
            >
          </p>
          <p>
            <a
              target="__blank"
              href="https://www.youtube.com/channel/UC80qTcwtMsUSE_FUOR0-glQ"
              class="icon"
            >
              <img accesskey="youtube" src="@/assets/svg/icons/youtube.svg" />
            </a>
            <a
              target="__blank"
              href="https://www.youtube.com/channel/UC80qTcwtMsUSE_FUOR0-glQ"
              >@evolve Tv</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="googlemap">
      <GoogleMap />
    </div>
  </div>
</template>
<script>
// import LayoutDefault from "../layouts/LayoutDefault.vue";
import GoogleMap from "../components/GoogleMap.vue";
export default {
  name: `Contact`,
  components: {
    GoogleMap,
  },
  created() {
    // this.$emit(`update:layout`, LayoutDefault);
  },
};
</script>
<style lang="scss">
.contact_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48rem;
  margin-top: 5rem;
  @include respond(phone) {
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }
  &_form {
    background-color: #00b3a2;
    width: 50%;
    height: 100%;
    padding: 40px 80px 0 80px;
    @include respond(phone) {
      width: 100%;
      padding: 20px;
    }
    h1 {
      font-size: 4rem;
      font-weight: 500;
      text-transform: uppercase;
      color: #000000a8;
      margin-left: 15%;
      position: relative;
      &::before {
        content: "";
        height: 5%;
        width: 15%;
        background: #ffffff;
        position: absolute;
        left: -18%;
        bottom: 50%;
      }
    }
    .input_container {
      input,
      textarea {
        padding: 1rem;
        padding-left: 3rem;
        width: 100%;
        resize: none;
        margin-top: 3rem;
        height: 5rem;
        background-color: #ffffff2f;
        border: 2px solid #ffffff;
        font-family: "Raleway", sans-serif !important;
        &:hover,
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #000000;
        }
      }
      textarea {
        height: 15rem;
      }
    }
    .submit_btn {
      width: 100%;
      height: 5rem;
      background-color: #ffffff62;
      border: 2px solid #ffffff;
      margin-top: 3rem;
      text-transform: uppercase;
      font-family: "Raleway", sans-serif !important;
    }
  }
}

.contact_img {
  width: 50%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .text_contacts {
    font-weight: 600;
    font-size: 1.5rem;
    p {
      margin-top: 2rem;
      display: flex;
    }
    a {
      color: #373737;
      text-decoration: none;
    }
    img {
      margin-right: 2rem;
      height: 2rem;
      fill: red;
    }
  }
  //  img{
  //    width: 100%;
  //    height: 100%;
  //    object-fit: cover;
  //  }
  @include respond(phone) {
    display: none;
  }
}

.googlemap {
  margin-top: 5rem;
}
</style>